@use "../../styles/vars";

#mainContainer {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container {
    display: flex;
    justify-content: flex-end;
}
area {
    cursor: pointer;
}
#map-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../../assets/images/bg-carte.webp);
    background-size: cover;
    transition-duration: 1.5s;
    transition-property: all;
    
}
#allMaps {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
}
#carte-radamir {
    max-height: 100vh;
    max-width: 100vw;
    transition-duration: 3s;
}
.carte-radamir {
    /* position: absolute;
    top: 0; */
    align-self: center;
}
.region {
    position: absolute; 
    left: 50%;
    transform: translateX(-50%);
    height: 100vh;
    transition-duration: 0.75s;
    z-index: -1;
}
@media (orientation: portrait) {
    @media screen and (max-width: 1000px) {
        #carte-radamir, .region {
            height: unset;
            width: 150vw;
            max-height: 100vh;
        }
    }
}    
.zindex {
    z-index: 30;
}
.scaled {
    height: 110vh;
}
.scaledKarnaclok {
    align-self: center;
    transform: translate(-48%);
}
.scaledKervollen {
    align-self: center;
    transform: translate(-50%);
}
.scaledVanna {
    align-self: center;
    transform: translate(-52%);
}
.scaledFeidlimid {
    align-self: center;
    transform: translate(-50%, 1%);
}
.scaledDroknor {
    align-self: center;
    transform: translate(-52%, -2%);
}
.darkened {
    filter: brightness(20%);
}
.clickThrough {
    pointer-events: none;
}
.infoBulle {
    background-color: rgba(0, 0, 0, .9);
    max-width: 450px;
    transition-duration: 1.5s;
    transition-property: all;
    color: #C8AD7F;
    padding: 2rem;
    border: solid 1px #C8AD7F;
    position: absolute;
    top: 5vh;
    left: 5vh;
    cursor: default;
    white-space: pre-wrap;
    font-size: 1.2rem;
    z-index: 400;
}
.navArrow, .indexLink {
    z-index: 12;
    transition: opacity 1.5s;
}
.navArrow {
    width: 2vw;
    margin-right: 15px;
    transition: all 0.5s;
}
.indexLink {
    position: absolute;
    left: .4vw;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 3s;
}
.indexLink:hover .navArrow {
    transform: translate(-10px);
}
.indexLink p {
    font-family: 'IM Fell English SC', serif;
    font-size: 2.8vw;
    color: #470703;
    transition: all 3s;
    margin-top: -3px;
}
.loadingContainer{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .loadingLogo {
        width: 45vw;
        z-index: 30;
    }
    .loadingText{
        font-size: 5rem;
        font-family: vars.$title-font;
        color: vars.$main-color;
        animation: blinking 3s linear infinite;
    }
    @keyframes blinking {
        50% {
            opacity: 0;
        }
    }
    @media screen and(max-width: 1024px) {
        .loadingText{
            font-size: 3rem;
        }
        @media screen and(max-width: 700px) {
            .loadingText{
                font-size: 2.5rem;
            }
            @media screen and(max-width: 426px) {
                .loadingLogo{
                    width: 70vw;
                }
                .loadingText{
                    font-size: 1.5rem;
                }
            }
        }
    }
}
.logo {
    position: absolute;
    width: 45vw;
    left: 10vw;
    bottom: 2vh;
    z-index: 30;
}
.loading {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.landscapeWarning {
    display: none;
}
@media screen and (max-width: 767px) {
    @media (orientation: portrait) {
        #mainContainer {
            display: none;
        }
        .landscapeWarning {
            display: block;
            font-family: 'IM Fell English SC', serif;
            font-size: 3rem;
            color: white;
            text-align: center;
            width: 100%;
            position: absolute;
            top: 35vh;
            /* left: 50%; */
            /* transform: translate(-50%, -50%); */
            animation: blinking 3s linear infinite;
        }
        @keyframes blinking {
            50% {
                opacity: 0;
            }
        }
        .landscapeBG {
            width: 100vw;
            height: 100vh;
            position: absolute;
            /* top: 50%;
            left: 50%; */
            /* transform: translate(-50%, -50%); */
            background: url(../../assets/images/bg-carte.webp);
            filter: brightness(20%);
        }
    }
}
@media (orientation: landscape) {
    .landscapeBG, .landscapeWarning {
        display: none;
    }
}
@media screen and (max-width: 1500px) {
    .infoBulle {
        font-size: 1rem;
        max-width: 20vw;
    }
    @media screen and (max-width: 1300px) {
        .infoBulle {
            font-size: .9rem;
            max-width: 20vw;
        }
    }
}
