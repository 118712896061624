@use "../../styles/vars";

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: hsla(0, 0%, 0%, 0.7);
  z-index: 1000;
  transition: all .5s;
}

.connection {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba( 0, 0, 0, 0.8);
  color: #C8AD7F;
  border: solid .5rem #63412a;
  padding: 2rem;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #C8AD7F;
  border: solid .5rem #C8AD7F;
  height: 375px;

  &__form, .form-field {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-bottom: 0.8rem;
  }

  h2 {
    margin-bottom: 1rem;
    font-family: vars.$title-font;
    font-size: 3rem;
  }

  label {
    cursor: default;
    margin-bottom: 0.3rem;
    font-family: vars.$title-font;
    font-size: 1.2rem;
  }

  input {
    padding: 0.3rem 0.5rem;
    width: 100%;
    color: #C8AD7F;
    background-color: rgba( 0, 0, 0, 0.1);
    border: 1px solid #C8AD7F;
    border-radius: 5px;
  }

  .connect-user {
    margin-top: 0.5rem;
    width: 100%;
    max-width: 200px;
    font-family: vars.$title-font;
    font-size: 1.2rem;
    color: vars.$main-color;
    background-color: vars.$light-bg;
    border: none;
    border-radius: 5px;
    transition: ease 0.3s;

    &:hover {
      color: #C8AD7F;
      background-color: rgba(200, 173, 127, 0.5);
    }
  }

  .close-connect {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    color: vars.$light-bg;
    cursor: pointer;
  }
}

.error {
  color: red;
  text-align: center;
  margin-bottom: 1em;
}
