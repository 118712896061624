@use "../../styles/vars";

.signup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: #C8AD7F;

  &-container {
    padding: 1rem 1.5rem;
    width: 300px;
    background-color: rgba( 0, 0, 0, 0.8);
    border: solid .5rem #C8AD7F;

    h1 {
      font-family: vars.$title-font;
      font-size: 2rem;
      text-align: center;
      margin-bottom: 1.5rem;
    }

    .signup__form {
      .form-field {
        width: 100%;
        margin-bottom: 0.8rem;
  
        label {
          display: block;
          margin-bottom: 0.3rem;
          font-family: vars.$title-font;
          font-size: 1.2rem;
        }
    
        input {
          padding: 0.3rem 0.5rem;
          width: 100%;
          color: #C8AD7F;
          background-color: rgba( 0, 0, 0, 0.1);
          border: 1px solid #C8AD7F;
          border-radius: 5px;
        }
      }
      h3 {
        font-family: vars.$title-font;
        font-size: 1.5rem;
        margin-top: 1rem;
      }
    }
  }
}