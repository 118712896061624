@use "../../styles/vars";

.wiki__create-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
  
  .create-block {
    width: 70%;
  
    .close-form {
      align-self: flex-end;
      margin-bottom: 0.8rem;
      font-size: 1.5rem;
      cursor: pointer;
    }

    input,
    textarea {
      margin-bottom: 0.8rem;
      padding: 0.3rem 0.7rem;
      width: 100%;
      color: vars.$main-color;
      background-color: vars.$light-bg;
      border: none;
      border-radius: 5px;
      resize: vertical;
    }

    textarea {
      height: 100px;
    }
  }
}

.controls {
  position: absolute;
  top: 25px;
  right: 0;
}

.admin-button {
  padding: 0.4rem 0.7rem;
  margin-bottom: 1rem;
  font-family: vars.$title-font;
  font-size: 1.2rem;
  color: vars.$light-bg;
  background-color: rgba(0, 0, 0, 0.8);
  border: 2px solid #C8AD7F;
  border-radius: 5px;
  transition: ease 0.3s;
  text-align: center;
  cursor: pointer;
  &:not(.noHover):hover {
    background-color: rgba(0, 0, 0, 0.6);
  }
}

.delete-wiki {
  border: 2px solid rgb(177, 49, 32);
}