@use "../../styles/vars";

.campaignList {
    box-sizing: border-box;
    display: flex;
    height: 100vh;
    overflow-y: hidden;

    h1 {
        position: absolute;
        top: 5vh;
        left: 5vw;
        font-size: 4.5vw;
        text-align: center;
        font-family: vars.$title-font;
        color: #1D0101;
        z-index: 2;
    }

    .sectionLeft {
        position: relative;

        width: 40vw;
        height: 100vh;
        padding: 5rem 1rem;

        .shipImg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-60%, -50%);
            width: 70%;
            z-index: -1;
        }
    }
    .sectionRight {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .addCampaign {
            align-self: flex-start;
            position: absolute;
            transform: translateY(32vh);
            border: none;
            background-color: rgba(0, 0, 0, 0);
            font-family: vars.$title-font;
            font-size: 2rem;
        }
        .campaignsContainer {
            display: flex;
            flex-direction: column;
            justify-content: safe center;
            align-items: safe center;
            align-content: flex-start;
            flex-wrap: wrap;
            width: 60vw;
            height: 55vh;
            box-sizing: border-box;
            overflow-x: auto;
    
            .card {
                position: relative;
                margin: 1rem;
                max-width: 302px;
                
                img {
                    width: 100%;
                }
                h3 {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 80%;
            
                    font-family: vars.$title-font;
                    font-size: 1.7vw;
                    text-align: center;
                    color: #1D0101;
                    @media screen and (min-width: 1920px) {
                        font-size: 36px;
                    }
                }
                .link {
                    position: absolute;
                    bottom: 20%;
                    left: 50%;
                    transform: translateX(-50%);
                }
    
                &:hover {
                    z-index: 600;
                    h3 {
                        text-shadow: 0px 0px 10px white;
                    }
                }
            }
        }
    }
    
    @media screen and (max-width: 1350px) {
        justify-content: center;
        .sectionLeft {
            position: absolute;
            .shipImg {
                width: 50vw;
            }
        }
        .sectionRight {
            .campaignsContainer {
                width: 80vw;
                .card {
                    width: 40vw;
                    h3 {
                        font-size: 2.5vw;
                    }
                }
            }
        }
        
    }
    @media screen and (max-width: 768px) {
        h1 {
            font-size: 10vw;
        }
        .sectionLeft {
            display: none;
        }
        .sectionRight {
            .campaignsContainer {
                width: 90vw;
                height: 80vh;
                margin-left: unset;
                align-items: center;
                margin-top: 15vh;
    
                .card {
                    position: relative;
                    margin: 1rem;
                    width: 50vw;
                    h3 {
                        font-size: 4vw;
                    }
                }    
            }
        }
        
        @media screen and (max-width: 425px) {
            .campaignsContainer {
                flex-wrap: unset;
                .card {
                    width: 100vw;
                    h3 {
                        font-size: 8vw;
                    }            
                }
            }
        }
    }
}

