@use "../../styles/vars";

.links-container {
  position: fixed;
  top: 15px;
  left: 0;
  z-index: 10;

  a {
    font-family: vars.$title-font;
  }

  .categorySelector {
    margin-left: 1rem;
    color: vars.$main-color;
    font-size: 32px;
    cursor: pointer;
  }

  .categorySelector::before {
    position: relative;
    top: 11px;
    content: url(../../assets/images/circle.webp);
  }
  
  .active::before {
    position: relative;
    top: 8px;
    content: url(../../assets/images/tickedCircle.webp);
  }
  @media screen and (max-height: 740px) {

    ul {
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .categorySelector {
        font-size: 32px;
      }
      @media screen and (max-height: 615px) {
        .categorySelector {
          font-size: 5vh;
        }
      }
    }
    .active {
      text-decoration: underline;
    }
    .categorySelector::before {
      content: "";
    }
    .active::before {
      content: "";
    }
  }
}

.wiki {
  margin: 4rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 50%;
  height: 100%;
  color: vars.$main-color;

  &__select {
    display: none;
    padding: 0.3rem;
    font-family: vars.$title-font;
    color: vars.$main-color;
    border: 2px solid vars.$main-color;
    border-radius: 5px;
    background-color: vars.$ligther-bg;
    outline: none;
  }

  &__title {
    text-align: center;
    margin: 1.5rem 0;
    font-family: vars.$title-font;
    text-transform: uppercase;
    font-size: 4rem;
  }

  &__page {
    // display: flex;
    // justify-content: space-between;
    // gap: 3rem;
    padding: 3rem 4rem 0;
    width: 100%;
    font-size: 1.4rem;
  }

  &__category {
    position: relative;
    margin-bottom: 35px;
    white-space: pre-wrap;
    line-height: 1.4;

    &-titleItem {
      position: relative;
      // left: 50%;
      // transform: translateX(-50%);
      width: 22vw;
      max-width: 320px;
    }

    &-titleImage {
      width: 100%;
    }

    &-title {
      position: absolute;
      top: 35%;
      left: 18%;
      // transform: translateX(-50%);
      font-family: vars.$title-font;
      color: vars.$main-color;
      font-size: 2rem;
    }
  }

  .category-container {
    flex: 85%;
    // margin-bottom: 3rem;
  }


  // .to-wiki {
  //   position: fixed;
  //   top: 50%;
  //   left: 5px;
  //   display: flex;
  //   align-items: center;
  //   gap: 1rem;
  //   font-size: 1.3rem;
  //   color: vars.$main-color;

  //   img {
  //     width: 40px;
  //     transition: ease 0.5s;
  //   }

  //   &:hover {
  //     img {
  //       transform: translateX(-10px);
  //     }
  //   }
  // }
}

@media screen and (max-width: 1024px) {
  .wiki {

    // .to-wiki {
    //   position: relative;
    //   margin-bottom: 1.5rem;
    //   font-size: 1.1rem;

    //   img {
    //     width: 20px;
    //   }
    // }

    &__select {
      display: block;
      margin-bottom: 1.7rem;
    }

    &__category {

      &-titleImage {
        display: none;
      
      }

      &-title {
        position: relative;
        top: 0;
        left: 0;
      }
    }
  }
  .links-container {
    display: none;
  }
}

@media screen and (max-width: 770px) {

  .wiki {
    max-width: 80%;
    text-align: center;

    &__title {
      margin-bottom: 1.7rem;
      font-size: 2.5rem;
    }

    &__page {
      padding: 0 1rem;
    }

    &__category {
      &-title {
        text-align: start;
      }

      &-content {
        text-align: left;
      }
    }
  }
}
