@use "../../styles/vars";

.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    color: #C8AD7F;

    &-container {
        padding: 1rem 1.5rem;
        width: 300px;
        background-color: rgba( 0, 0, 0, 0.8);
        border: solid .5rem #C8AD7F;

        h1 {
            font-family: vars.$title-font;
            font-size: 2rem;
            text-align: center;
            margin-bottom: 1.5rem;
        }
        
        .profile__infos {
            width: 100%;
            margin-bottom: 0.8rem;

            .profile__info {
                &-title {
                    display: block;
                    margin-bottom: 0.3rem;
                    margin-top: 0.7rem;
                    font-family: vars.$title-font;
                    font-size: 1.2rem;
                }

                &-content {
                    display: block;
                    margin-bottom: 1rem;
                    font-family: vars.$title-font;
                    font-size: 1.2rem;
                    background-color: rgba( 0, 0, 0, 0.1);
                    border-radius: 5px;
                    padding: 0.4rem;
                }
            }


                label {
                    display: block;
                    margin-bottom: 0.3rem;
                    font-family: vars.$title-font;
                    font-size: 1.2rem;
                }
              
                input {
                    padding: 0.3rem 0.5rem;
                    width: 100%;
                    color: #C8AD7F;
                    background-color: rgba( 0, 0, 0, 0.1);
                    border: 1px solid #C8AD7F;
                    border-radius: 5px;
                }

                button {
                    padding: 0.3rem;
                    margin-top: 1.1rem;
                    width: 100%;
                    font-family: vars.$title-font;
                    font-size: 1.2rem;
                    color: vars.$main-color;
                    background-color: vars.$light-bg;
                    border: none;
                    border-radius: 5px;
                    transition: ease 0.3s;
            
                    &:hover {
                      color: #C8AD7F;
                      background-color: rgba(200, 173, 127, 0.5);
                    }

                    &.delete-user {
                        border: 1px solid #b13120;
                    }
                }
        }
    }
}