@use "../../styles/vars";

.campaign {
    display: flex;
    flex-direction: column;
    h1 {
        align-self: center;
        margin-top: 2rem;
        font-family: vars.$title-font;
        font-size: 5rem;
        text-align: center;
        max-width: 40vw;
        height: 10vh;
        @media screen and (max-width: 1500px) {
            font-size: 3rem;
            @media screen and (max-width: 600px) {
                align-self: flex-start;
                text-align: unset;
                max-width: 80vw;
                margin-left: 1rem;
                @media screen and (max-width: 365px){
                    font-size: 2.5rem;
                    margin-bottom: 1rem;
                }
            }
        }
    }
    .pageOne {
        position: relative;
        display: flex;
        height: 90vh;
        .sectionCarte {
            width: 40vw;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
                width: 100%;
                transform: translateX(2rem);
            }
        }
        .sectionResume {
            width: 60vw;
            height: 100%;
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            line-height: normal;
            white-space: pre-wrap;
            h2 {
                font-size: 3rem;
                font-family: vars.$title-font;
                margin-bottom: 1rem;
            }
            p {
                margin: 1rem 0 2rem;
                width: 70%;
                min-height: 5vh;
                max-height: 50vh;
                font-size: 1.2rem;
                overflow: auto;
            }
            .playersDiv {
                width: 70%;
                display: flex;
                flex-direction: column;
                row-gap: 1.5rem;
                // flex-wrap: wrap;
                h3{
                    font-size: 2.5rem;
                    text-align: center;
                    font-family: vars.$title-font;
                }
                .playerCards {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-grow: 1;
                    column-gap: 2rem;
                    .characterCard {
                        width: 20%;
                        height: 4rem;
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        margin-bottom: 0;
                        text-align: center;
                        cursor: auto;
                        p {
                            width: 80%;
                            text-overflow: wrap;
                            margin: unset;
                            min-height: unset;
                            max-height: unset;
                            overflow: auto;
                        }
                        .deleteCard{
                            cursor: pointer;
                            
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 1500px) {
            .sectionResume {
                h2 {
                    font-size: 2rem;
                }
            }
            @media screen and (max-width: 900px) {
                align-items: center;
                justify-content: center;
                .sectionCarte {
                    display: none;
                }
                .sectionResume {
                    width: 90%;
                    .playersDiv {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        row-gap: 1.5rem;
                        h3{
                            font-size: 2.5rem;
                            text-align: center;
                            font-family: vars.$title-font;
                        }
                        .playerCards {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            flex-grow: 1;
                            column-gap: 2rem;
                            .characterCard {
                                width: 45%;
                                height: 3rem;
                                font-size: 1rem;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                }
                @media screen and (max-width: 430px) {
                    .sectionResume{
                        justify-content: unset;
                        height: unset;
                        p{
                            height: unset;
                            width: 100%;
                            max-height: 15vh;
                        }
                    }
                    @media screen and (max-width: 365px) {
                        .sectionResume {
                            h2 {
                                font-size: 1.5rem;
                                margin-bottom: 0;
                                text-decoration: underline;
                            }
                            p {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .pageTwo {
        position: relative;
        width: 100vw;
        display: flex;
        flex-direction: column;
        h2 {
            position: absolute;
            font-size: 4rem;
            font-family: vars.$title-font;
            margin-left: 4rem;
            margin-bottom: 1rem;
            text-decoration: underline;
            @media screen and (max-width: 365px) {
                font-size: 3rem;
            }
        }
        .bg2 {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 115vh;
            width: 100vw;
            z-index: -1;
            @media screen and (max-width: 600px) {
                object-fit: cover;
                height: 170vh;
                width: 100vw;
            }
        }
        .allNotes {
            margin-top: 20vh;
            display: flex;
            h3 {
                position: absolute;
                font-size: 3rem;
                font-family: vars.$title-font;
                align-self: flex-start;
                transform: translateY(-7vh);
                text-decoration: underline;
                @media screen and (max-width: 1200px) {
                    font-size: 2rem;
                    @media screen and (max-width: 830px) {
                        font-size: 1.5rem;
                    }
                }
            }
            .noteContainer {
                position: relative;
                width: 40%;
                max-width: 12vw;
                transition: transform .5s;
                border: none;
                background-color: rgba(0, 0, 0, 0);
                img {
                    width: 100%;
                    opacity: .9;
                }
                h4 {
                    font-family: vars.$title-font;
                    font-size: 1.25vw;
                    text-align: center;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    max-width: 70%;
                    max-height: 80%;
                    transform: translate(-50%, -50%);
                }
            }
            .noteContainer:hover{
                transform: scale(1.05);
            }
            .notesPrivees, .notesPubliques, .imageDiscord {
                margin-left: 3vw;
                height: 60vh;
                width: 30vw;
                display: flex;
                flex-wrap: wrap;
                align-content: flex-start;
                align-items: center;
                overflow-y: auto;
                border-right: 5px solid #3B2D19;
            }
            .imageDiscord {
                margin-left: 1.5vw;
                border: none;
                align-content: unset;
                h3 {
                    margin-left: 1.5vw;
                }
                div{
                    overflow: visible;
                }
                .imgContainer {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    &:hover{
                        &::after {
                        content: "Créer une note à partir de cette image";
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        color: #C8AD7F;
                        font-family: vars.$title-font;
                        font-size: 2rem;
                        text-align: center;
                        pointer-events: none;
                        }
                        .discordImg {
                            filter: brightness(.2);
                            cursor: pointer;
                        }
                    }
                    .discordImg {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
            @media screen and (max-width: 600px) {
                flex-direction: column-reverse;
                .notesPrivees, .notesPubliques, .imageDiscord {
                    border: none;
                    height: 30vh;
                    width: 90vw;
                    flex-direction: column;
                    margin-bottom: 4rem;
                    overflow-y: auto;
                }
                .notesPrivees {
                    margin-bottom: 0;
                }
                .noteContainer {
                    width: 35vw;
                    max-width: unset;
                    img {
                        width: 100%;
                        opacity: .9;
                    }
                    h4 {
                        font-size: 100%;
                    }
                }
                .imageDiscord {
                    h3 {
                        align-self: center;
                    }
                }
                @media screen and (max-width: 365px) {
                    .notesPrivees, .notesPubliques, .imageDiscord {
                        h3 {
                            font-size: 2.5rem;
                        }
                    }
                    .imageDiscord {
                        h3 {
                            transform: translateY(-8vh);
                        }
                    }
                }
            }
        }
        .addNote {
            width: 30vw;
            align-self: center;
            margin-top: 2rem;
            margin-bottom: .5rem;
            border: none;
            background-color: rgba(0, 0, 0, 0);
            font-family: vars.$title-font;
            font-size: 2rem;
        }
        .addNote:hover {
            text-decoration: underline;
        }
        @media screen and (max-width: 600px) {
            .addNote {
                margin-top: 0;
                width: unset;
                margin-bottom: 1.5rem;
                @media screen and (max-width: 365px) {
                    font-size: 1.5rem;
                }
            }
        }
    }
}