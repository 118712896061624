@use "vars";
@use "reset";

// DEBUG
// *{
//   outline: 1px solid red;
//  }


html {
  font-family: serif;
}
*{
  vertical-align: top;
  scrollbar-width: thin;
  scrollbar-color: #C8AD7F darkgrey;
  &::-webkit-scrollbar{
    width: 10px;
    &-track{
      background-color: darkgrey;
    }
    &-thumb{
      background-color: #C8AD7F;
      border-radius: 20px;
    }
  }
}
body {
  position: relative;
  background: url("../assets/images/bg-carte.webp");
  min-height: 100vh;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #C8AD7F darkgrey;
  &::-webkit-scrollbar{
    width: .8vw;
    &-track{
      background-color: darkgrey;
    }
    &-thumb{
      background-color: #C8AD7F;
      border-radius: 20px;
    }
  }
}
.noDisplay {
    opacity: 0%;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input, 
textarea {
  font-family: serif;
  font-size: 1.1rem;
}

textarea:focus {
  outline: none;
}

.controls-button {
  margin-left: 0.5rem;
  font-size: 1.3rem;
  cursor: pointer;
  transition: ease 0.3s;

  &:hover {
    color: #C8AD7F;
  }
}

.signButton {
  padding: 0.3rem;
  margin-top: 0.5rem;
  width: 100%;
  font-family: vars.$title-font;
  font-size: 1.2rem;
  color: vars.$main-color;
  background-color: vars.$light-bg;
  border: none;
  border-radius: 5px;
  transition: ease 0.3s;

  &:hover {
    color: #C8AD7F;
    background-color: rgba(200, 173, 127, 0.5);
  }
}