@use "../../styles/vars";

.about-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../assets/images/bg-carte.webp");

  .about {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 2rem;
    color: vars.$main-color;

    &__title {
      margin-bottom: 3rem;
      font-family: vars.$title-font;
      font-size: 4rem;
      text-transform: uppercase;
    }

    &__subTitle {
      margin-bottom: 3rem;
      font-family: vars.$title-font;
      font-size: 2rem;
      text-transform: uppercase;
    }

    p {
      margin-bottom: 3rem;
      font-size: 1.5rem;
      text-align: justify;
      width: 80%;
      color: black;
    }

    &__team {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 2.5rem;

      .member {

        border: 8px ridge #C8AD7F;
        background-color: rgba(0,0,0,0.8);

        &__title {
          margin-bottom: 0.8rem;
          font-family: vars.$title-font;
          font-size: 1.7rem;
          text-align: center;
          color: #C8AD7F;
        }

        &__role {
          margin-bottom: 0.8rem;
          font-family: vars.$title-font;
          font-size: 1.3rem;
          text-align: center;
          color: #C8AD7F;
        }

        &__content {
          display: flex;
          gap: 0.8rem;
        }

        &__img {
          width: 20rem;
          // delete following line when we have pictures
          font-size: 100px;
          margin-bottom: 1rem;
        }

        &__links {
          display: flex;
          flex-direction: column;
          gap: 0.3rem;
          justify-content: center;
          text-align: center;
          height: 100%;
          margin-bottom: 0.8rem;
        }

        &__link {
          font-size: 1.2rem;

          a {
            font-family: "Times New Roman", Times, serif;
            color: #C8AD7F
          }

          &-icon {
            margin-right: 0.3rem;
          }
        }

      }
    }
  }
}
