.menu, .menuMobileToggle {
    position: fixed;
    top: 2vh;
    right: .4vw;
    z-index: 12;
}
.menuMobile {
    display: none;
}
.cursorPointer {
    cursor: pointer;
}
.menuImg, .menu {
    width: 20vw;
}
.noDisplay {
    opacity: 0%;
}
.inactive {
    display: none;
}
.navLinks {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -35%);

    height: 60%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.links {
    font-family: 'IM Fell English SC', serif;
    font-size: 1.6vw;
    text-align: center;
    color: #470703;
    z-index: 20;
}
.selected {
    cursor: default;
    text-decoration: underline;
}
.menu-button {
    background-color: unset;
    padding: unset;
    border: unset;
    text-align: left;
}
// .selected::after {
//     margin-left: 10px;
//     content: "";
//     width: 1.8vw;
//     height: 1.48vw;
//     background-image: url(../../assets/images/quill-resized.webp);
//     background-size: cover;
//     background-repeat: no-repeat;
//     display: inline-block;
// }

@media screen and (max-width: 1200px) {
    .wikis {
        transition: all 1s;
    }
    .menu {
        display: none;
    }
    .menuMobile {
        display: block;
        transition: all 2s;
    }
    .selected::after {
        content: unset;
    }
    .navLinksMobile {
        position: fixed;
        top: 50vh;
        left: 50%;
        width: 210px;
        transform: translate(-50%, -40%);
        z-index: 100;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        // transition: opacity 1s;

        &__img {
            position: fixed;
            top: 50vh;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 50;
            width: 425px;
            // transition: opacity 1s, box-shadow 1s, background-color 1s;
        }
    }
    .links {
        font-size: 2rem;
        margin-bottom: 1rem;
        // transition: all 1s;
    }
    .menuMobileToggle {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        // transition: box-shadow 1s, background-color 1s;
        width: 10vh;
        z-index: 80;

        &__barreDessus,
        &__barreDessous {
            transition: transform 1s;
        }

        .openedDessus {
            transform: translateY(50%)  rotate(227.5deg);
            filter: brightness(2.5);
        }

        .openedDessous {
            transform: translateY(-50%)  rotate(132.5deg) translateY(-10%);
            filter: brightness(2.5);
        }
    }
    .boxShadow::before {
        content: "";
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: rgba( 0, 0, 0, 0.8);
        z-index: 50;
    }
    .displayNone {
        z-index: -100;
        opacity: 0;
    }

    @media (orientation: landscape) {
        @media screen and (max-height: 500px) {
            // .links {
            //     font-size: 8vh;
            // }
            .menuMobileToggle {
                margin-top: 15px;
                width: 15vh;
            }
        }
    }
}

@media screen and (max-width: 470px) {
    .navLinksMobile__img {
        width: 100vw;
    }

    .navLinksMobile {
        width: 175px;
    }

    .links {
        font-size: 1.7rem;
    }
}
