.uselessNavBar {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1.5rem;
    z-index: 200;
    
    ul {
        display: flex;
        justify-content: flex-end;
    }
    
    &__link {
        margin-right: 1rem;
    }
}